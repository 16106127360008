import { css } from '@emotion/css';
import { cloneDeep } from 'lodash';
import React from 'react';

import { GrafanaTheme2, NavModelItem } from '@grafana/data';
import { Components } from '@grafana/e2e-selectors';
import { Dropdown, IconButton, ToolbarButton, useStyles2 } from '@grafana/ui';
import { config } from 'app/core/config';
import { t } from 'app/core/internationalization';
// import { HOME_NAV_ID } from 'app/core/reducers/navModel';
import { ContextSrv } from 'app/core/services/context_srv';
import { useSelector } from 'app/types';

import { Branding } from '../../Branding/Branding';
// import { buildBreadcrumbs } from '../../Breadcrumbs/utils';
import { enrichHelpItem } from '../MegaMenu/utils';
import { OrganizationSwitcher } from '../OrganizationSwitcher/OrganizationSwitcher';
import { QuickAdd } from '../QuickAdd/QuickAdd';
import { SignInLink } from '../TopBar/SignInLink';
import { TopNavBarMenu } from '../TopBar/TopNavBarMenu';
import { TopSearchBarSection } from '../TopBar/TopSearchBarSection';
import { TOP_BAR_LEVEL_HEIGHT } from '../types';

import { NavToolbarSeparator } from './NavToolbarSeparator';

export interface Props {
  onToggleSearchBar(): void;
  onToggleMegaMenu(): void;
  onToggleKioskMode(): void;
  searchBarHidden?: boolean;
  sectionNav: NavModelItem;
  pageNav?: NavModelItem;
  actions: React.ReactNode;
}

export function NavToolbar({
  actions,
  searchBarHidden,
  sectionNav,
  pageNav,
  onToggleMegaMenu,
  onToggleSearchBar,
  onToggleKioskMode,
}: Props) {
  // const homeNav = useSelector((state) => state.navIndex)[HOME_NAV_ID];
  const styles = useStyles2(getStyles);
  // const breadcrumbs = buildBreadcrumbs(sectionNav, pageNav, homeNav);
  let homeUrl = config.appSubUrl || '/';

  const navIndex = useSelector((state) => state.navIndex);
  const helpNode = cloneDeep(navIndex['help']);
  const contextSrv = new ContextSrv();
  const enrichedHelpNode = helpNode && contextSrv.hasRole('Admin') ? enrichHelpItem(helpNode) : undefined;
  const profileNode = navIndex['profile'];

  return (
    <div data-testid={Components.NavToolbar.container} className={styles.pageToolbar}>
      <TopSearchBarSection>
        <a className={styles.logo} href={homeUrl} title="Go to home">
          <Branding.MenuLogo className={styles.img} />
        </a>
        <OrganizationSwitcher />
      </TopSearchBarSection>
      <div className={styles.menuButton}>
        <IconButton
          name="bars"
          tooltip={t('navigation.toolbar.toggle-menu', 'Toggle menu')}
          tooltipPlacement="bottom"
          size="xl"
          onClick={onToggleMegaMenu}
        />
      </div>
      {/* <Breadcrumbs breadcrumbs={breadcrumbs} className={styles.breadcrumbsWrapper} /> */}
      <div className={styles.actions}>
        {actions}
        {actions && <NavToolbarSeparator />}
      </div>
      <TopSearchBarSection align="right">
        <QuickAdd />
        {enrichedHelpNode && (
          <Dropdown overlay={() => <TopNavBarMenu node={enrichedHelpNode} />} placement="bottom-end">
            <ToolbarButton iconOnly icon="question-circle" aria-label="Help" />
          </Dropdown>
        )}
        {!contextSrv.user.isSignedIn && <SignInLink />}
        {profileNode && (
          <Dropdown overlay={() => <TopNavBarMenu node={profileNode} />} placement="bottom-end">
            <ToolbarButton
              className={styles.profileButton}
              imgSrc={contextSrv.user.gravatarUrl}
              imgAlt="User avatar"
              aria-label="Profile"
            />
          </Dropdown>
        )}
      </TopSearchBarSection>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    img: css({
      height: theme.spacing(3),
    }),
    logo: css({
      display: 'flex',
    }),
    breadcrumbsWrapper: css({
      display: 'flex',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        minWidth: '50%',
      },
    }),
    pageToolbar: css({
      height: TOP_BAR_LEVEL_HEIGHT,
      display: 'flex',
      padding: theme.spacing(0, 1, 0, 2),
      alignItems: 'center',
    }),
    menuButton: css({
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
    }),
    actions: css({
      label: 'NavToolbar-actions',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      paddingLeft: theme.spacing(1),
      flexGrow: 1,
      gap: theme.spacing(0.5),
      minWidth: 0,

      '.body-drawer-open &': {
        display: 'none',
      },
    }),
    profileButton: css({
      padding: theme.spacing(0, 0.25),
      marginLeft: theme.spacing(1),
      img: {
        borderRadius: theme.shape.radius.circle,
        height: '24px',
        marginRight: 0,
        width: '24px',
      },
    }),
  };
};

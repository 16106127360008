import { getBackendSrv } from '@grafana/runtime/src/services/backendSrv';

import { CustomLink } from './custom_links';

export class FrivindCustomDashboardLinks {
  constructor() {}

  // Async get theme from /api/orgs/:orgId/dashboardLinks
  private async getCustomLinks(): Promise<CustomLink[]> {
    const links: CustomLink[] = await getBackendSrv().get(`/api/org/dashboardLinks`);

    // Throw error if no link is found
    if (!links) {
      throw new Error('No links found');
    }

    return links;
  }

  async loadCustomDashboardLinks(): Promise<CustomLink[]> {
    try {
      const customLink = await this.getCustomLinks();
      return customLink;
    } catch (err) {
      throw err;
    }
  }

  async saveCustomDashboardLinks(link: CustomLink): Promise<void> {
    try {
      await getBackendSrv().post(`/api/org/dashboardLinks`, link);
    } catch (err) {
      throw err;
    }
  }
}

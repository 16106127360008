import { css } from '@emotion/css';
import { merge } from 'lodash';
import React, { useEffect, useState } from 'react';

import { FieldConfigEditorProps, GrafanaTheme2, SelectableValue } from '@grafana/data';
import { config } from '@grafana/runtime';
import { TableCellOptions } from '@grafana/schema';
import {
  ColorPicker,
  Field,
  Input,
  RadioButtonGroup,
  Select,
  Switch,
  TableCellDisplayMode,
  useStyles2,
} from '@grafana/ui';

import { BarGaugeCellOptionsEditor } from './cells/BarGaugeCellOptionsEditor';
import { ColorBackgroundCellOptionsEditor } from './cells/ColorBackgroundCellOptionsEditor';
import { SparklineCellOptionsEditor } from './cells/SparklineCellOptionsEditor';

interface HighlightOption {
  basedOn: { name: string; column: number };
  matcher: {
    operator: string;
    value: string;
  };
  color: string;
  backgroundColor: string;
  icon?: string;
  iconPosition?: 'start' | 'end';
  iconColor?: string;
  iconSize?: 'small' | 'medium' | 'large';
  isActive: boolean;
  tooltip?: string;
  iconAnimation?: string;
}

// The props that any cell type editor are expected
// to handle. In this case the generic type should
// be a discriminated interface of TableCellOptions
export interface TableCellEditorProps<T> {
  cellOptions: T;
  onChange: (value: T) => void;
}

interface Props {
  value: TableCellOptions;
  onChange: (v: TableCellOptions) => void;
}

export const UpdateBasedOnOptionEditor: React.FC<FieldConfigEditorProps<HighlightOption[], any>> = ({
  value = [],
  onChange,
  context,
}) => {
  // Ensure `value` is an array when initializing state
  const [highlightRules, setHighlightRules] = useState<HighlightOption[]>(Array.isArray(value) ? value : []);

  const [columnOptions, setColumnOptions] = useState<Array<SelectableValue<{ name: string; column: number }>>>([]);
  const operatorOptions = [
    { label: '=', value: '=' },
    { label: '<', value: '<' },
    { label: '>', value: '>' },
    { label: 'true', value: 'true' },
    { label: 'false', value: 'false' },
    { label: 'null', value: 'null' },
    { label: '!null', value: '!null' },
  ];
  const iconAnimationOptions = [
    { label: 'None', value: '' },
    { label: 'Pulse', value: 'pulse' },
    { label: 'Bounce', value: 'bounce' },
    { label: 'Rotate', value: 'rotate' },
    { label: 'Fade', value: 'fade' },
  ];

  // Effect to dynamically retrieve column names and indexes from the table
  useEffect(() => {
    if (context.data) {
      const columns = context.data.flatMap((frame) =>
        frame.fields.map((field, colIdx) => ({
          label: `${field.name} (Column: ${colIdx})`,
          value: { name: field.name, column: colIdx },
        }))
      );
      setColumnOptions(columns);
    }
  }, [context.data]);

  const addHighlightRule = () => {
    const newRule: HighlightOption = {
      basedOn: { name: '', column: -1 },
      matcher: { operator: '=', value: '' },
      color: '#E02F44',
      backgroundColor: '#FFFFFF',
      icon: '',
      iconPosition: 'start',
      iconColor: '#000000',
      iconSize: 'small',
      isActive: true,
      tooltip: '',
      iconAnimation: '',
    };
    const updatedRules = [...highlightRules, newRule];
    setHighlightRules(updatedRules);
    onChange(updatedRules);
  };

  const removeHighlightRule = (index: number) => {
    const updatedRules = highlightRules.filter((_, i) => i !== index);
    console.log(updatedRules);
    setHighlightRules(updatedRules.filter(Boolean)); // Filter out any undefined or empty values
    onChange(updatedRules.filter(Boolean));
  };

  const updateHighlightRule = (index: number, updatedRule: HighlightOption) => {
    const updatedRules = highlightRules.map((rule, i) => (i === index ? updatedRule : rule));
    setHighlightRules(updatedRules);
    onChange(updatedRules);
  };

  return (
    <div>
      {highlightRules.map((rule, index) => (
        <div key={index} style={{ marginBottom: '10px', padding: '10px', border: '1px solid #ddd' }}>
          <Field label={`Activate Highlighting - Rule ${index + 1}`}>
            <Switch
              value={rule.isActive}
              onChange={(e) => updateHighlightRule(index, { ...rule, isActive: e.currentTarget.checked })}
            />
          </Field>
          <Field label="Based on Column">
            <Select
              options={columnOptions}
              value={{ label: rule.basedOn.name, value: rule.basedOn }}
              onChange={(option) =>
                updateHighlightRule(index, { ...rule, basedOn: option.value || { name: '', column: -1 } })
              }
            />
          </Field>
          <Field label="Operator">
            <Select
              options={operatorOptions}
              value={{ label: rule.matcher.operator, value: rule.matcher.operator }}
              onChange={(option) =>
                updateHighlightRule(index, {
                  ...rule,
                  matcher: { ...rule.matcher, operator: option.value || '=' },
                })
              }
            />
          </Field>
          <Field label="Match Condition Value">
            <Input
              value={rule.matcher.value}
              placeholder="Enter match condition, e.g., true"
              onChange={(e) =>
                updateHighlightRule(index, {
                  ...rule,
                  matcher: { ...rule.matcher, value: (e.target as HTMLInputElement).value },
                })
              }
            />
          </Field>
          <Field label="Text Color">
            <ColorPicker color={rule.color} onChange={(color) => updateHighlightRule(index, { ...rule, color })} />
          </Field>
          <Field label="Background Color">
            <ColorPicker
              color={rule.backgroundColor}
              onChange={(color) => updateHighlightRule(index, { ...rule, backgroundColor: color })}
            />
          </Field>
          <Field label="Icon">
            <Input
              value={rule.icon || ''}
              placeholder="Enter icon name, e.g., 'star'"
              onChange={(e) => updateHighlightRule(index, { ...rule, icon: (e.target as HTMLInputElement).value })}
            />
          </Field>
          <Field label="Icon Position">
            <RadioButtonGroup
              options={[
                { label: 'Left', value: 'start' as 'start' },
                { label: 'Right', value: 'end' as 'end' },
              ]}
              value={rule.iconPosition || 'start'}
              onChange={(position) =>
                updateHighlightRule(index, { ...rule, iconPosition: position as 'start' | 'end' })
              }
            />
          </Field>
          <Field label="Icon Size">
            <RadioButtonGroup
              options={[
                { label: 'Small', value: 'small' as 'small' },
                { label: 'Medium', value: 'medium' as 'medium' },
                { label: 'Large', value: 'large' as 'large' },
              ]}
              value={rule.iconSize || 'small'}
              onChange={(size) =>
                updateHighlightRule(index, { ...rule, iconSize: size as 'small' | 'medium' | 'large' })
              }
            />
          </Field>
          <Field label="Icon Color">
            <ColorPicker
              color={rule.iconColor || '#000000'}
              onChange={(color) => updateHighlightRule(index, { ...rule, iconColor: color })}
            />
          </Field>
          <Field label="Tooltip">
            <Input
              value={rule.tooltip || ''}
              placeholder="Enter tooltip text"
              onChange={(e) => updateHighlightRule(index, { ...rule, tooltip: (e.target as HTMLInputElement).value })}
            />
          </Field>
          <Field label="Icon Animation">
            <Select
              options={iconAnimationOptions}
              value={{ label: rule.iconAnimation || 'None', value: rule.iconAnimation || '' }}
              onChange={(option) => updateHighlightRule(index, { ...rule, iconAnimation: option.value || '' })}
            />
          </Field>
          <button onClick={() => removeHighlightRule(index)}>Remove Rule</button>
        </div>
      ))}
      <button onClick={addHighlightRule}>Add Rule</button>
    </div>
  );
};

export const TableCellOptionEditor = ({ value, onChange }: Props) => {
  const cellType = value.type;
  const styles = useStyles2(getStyles);
  const currentMode = cellDisplayModeOptions.find((o) => o.value!.type === cellType)!;
  let [settingCache, setSettingCache] = useState<Record<string, TableCellOptions>>({});

  // Update display mode on change
  const onCellTypeChange = (v: SelectableValue<TableCellOptions>) => {
    if (v.value !== undefined) {
      // Set the new type of cell starting
      // with default settings
      value = v.value;

      // When changing cell type see if there were previously stored
      // settings and merge those with the changed value
      if (settingCache[value.type] !== undefined && Object.keys(settingCache[value.type]).length > 1) {
        value = merge(value, settingCache[value.type]);
      }

      onChange(value);
    }
  };

  // When options for a cell change we merge
  // any option changes with our options object
  const onCellOptionsChange = (options: TableCellOptions) => {
    settingCache[value.type] = merge(value, options);
    setSettingCache(settingCache);
    onChange(settingCache[value.type]);
  };

  // Setup and inject editor
  return (
    <div className={styles.fixBottomMargin}>
      <Field>
        <Select options={cellDisplayModeOptions} value={currentMode} onChange={onCellTypeChange} />
      </Field>
      {cellType === TableCellDisplayMode.Gauge && (
        <BarGaugeCellOptionsEditor cellOptions={value} onChange={onCellOptionsChange} />
      )}
      {cellType === TableCellDisplayMode.ColorBackground && (
        <ColorBackgroundCellOptionsEditor cellOptions={value} onChange={onCellOptionsChange} />
      )}
      {cellType === TableCellDisplayMode.Sparkline && (
        <SparklineCellOptionsEditor cellOptions={value} onChange={onCellOptionsChange} />
      )}
    </div>
  );
};

const SparklineDisplayModeOption: SelectableValue<TableCellOptions> = {
  value: { type: TableCellDisplayMode.Sparkline },
  label: 'Sparkline',
};

const cellDisplayModeOptions: Array<SelectableValue<TableCellOptions>> = [
  { value: { type: TableCellDisplayMode.Auto }, label: 'Auto' },
  ...(config.featureToggles.timeSeriesTable ? [SparklineDisplayModeOption] : []),
  { value: { type: TableCellDisplayMode.ColorText }, label: 'Colored text' },
  { value: { type: TableCellDisplayMode.ColorBackground }, label: 'Colored background' },
  { value: { type: TableCellDisplayMode.Gauge }, label: 'Gauge' },
  { value: { type: TableCellDisplayMode.JSONView }, label: 'JSON View' },
  { value: { type: TableCellDisplayMode.Image }, label: 'Image' },
];

const getStyles = (theme: GrafanaTheme2) => ({
  fixBottomMargin: css({
    marginBottom: theme.spacing(-2),
  }),
});

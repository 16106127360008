import React, { useEffect, useState } from 'react';

import { NavModelItem, SelectableValue } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { Select } from '@grafana/ui';
import { FrivindCustomDashboardLinks } from 'app/core/components/FrivindCustomDashboardLinks/FrivindCustomDashboardLink';
import { CustomLink } from 'app/core/components/FrivindCustomDashboardLinks/custom_links';
import { Page } from 'app/core/components/Page/Page';

import { LinkSettingsEdit, LinkSettingsList } from '../LinksSettings';
import { newLink } from '../LinksSettings/LinkSettingsEdit';

import { ListNewButton } from './ListNewButton';
import { SettingsPageProps } from './types';

export type LinkSettingsMode = 'list' | 'new' | 'edit';

export function LinksSettings({ dashboard, sectionNav, editIndex }: SettingsPageProps) {
  const [isNew, setIsNew] = useState<boolean>(false);
  const [customLinks, setCustomLinks] = useState<SelectableValue[]>([]);
  const [renderKey, setRenderKey] = useState(0);

  const customDashboardLinks = new FrivindCustomDashboardLinks();

  const loadLinks = async () => {
    try {
      const links: CustomLink[] = await customDashboardLinks.loadCustomDashboardLinks();
      const linksAsSelectableValue = links.map((link) => {
        return {
          label: link?.Title || 'Untitled',
          value: {
            ...link,
            Links: JSON.parse(link.Links),
          },
        };
      });
      setCustomLinks(linksAsSelectableValue);
    } catch (err) {
      console.error('Failed to load custom links', err);
    }
  };

  useEffect(() => {
    loadLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveCustomDashboardLinks = async (link: CustomLink) => {
    if (!link.Links) {
      console.error('No links to save', link);
      return;
    }

    try {
      await customDashboardLinks.saveCustomDashboardLinks(link);
      console.log('Saved custom links', link);
      await loadLinks(); // Reload the links after saving
    } catch (err) {
      console.error('Failed to save custom links', err);
    }
  };

  const selectLinks = (value: SelectableValue<any>) => {
    if (!value.value.Links || !value.value.Links.length) {
      console.error('No links found in selected custom link', value);
      return;
    }
    dashboard.links = value.value.Links;
    setRenderKey((prevKey) => prevKey + 1);
  };

  const onGoBack = () => {
    setIsNew(false);
    locationService.partial({ editIndex: undefined });
  };

  const onNew = () => {
    dashboard.links = [...dashboard.links, { ...newLink }];
    setIsNew(true);
    locationService.partial({ editIndex: dashboard.links.length - 1 });
    setRenderKey((prevKey) => prevKey + 1);
  };

  const onEdit = (idx: number) => {
    setIsNew(false);
    locationService.partial({ editIndex: idx });
  };

  const onSaveLinks = () => {
    const now = new Date();
    const title = `${dashboard.title} ${now.toISOString().slice(0, 19).replace('T', ' ')}`;

    saveCustomDashboardLinks({
      Title: title,
      Links: JSON.stringify(dashboard.links),
    });
  };

  const isEditing = editIndex !== undefined;

  let pageNav: NavModelItem | undefined;
  if (isEditing) {
    const title = isNew ? 'New link' : 'Edit link';
    const description = isNew ? 'Create a new link on your dashboard' : 'Edit a specific link of your dashboard';
    pageNav = {
      text: title,
      subTitle: description,
    };
  }

  return (
    <Page navModel={sectionNav} pageNav={pageNav}>
      {!isEditing && (
        <>
          <Select options={customLinks} placeholder="Select preset links list" onChange={selectLinks} />
          {dashboard.links.length > 0 && <ListNewButton onClick={onSaveLinks}>Save current links</ListNewButton>}
        </>
      )}
      {!isEditing && <LinkSettingsList key={renderKey} dashboard={dashboard} onNew={onNew} onEdit={onEdit} />}
      {isEditing && <LinkSettingsEdit dashboard={dashboard} editLinkIdx={editIndex} onGoBack={onGoBack} />}
    </Page>
  );
}

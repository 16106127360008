import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2, LinkTarget } from '@grafana/data';
import { config } from '@grafana/runtime';
import { IconName, useStyles2 } from '@grafana/ui';

export interface FooterLink {
  target: LinkTarget;
  text: string;
  id: string;
  icon?: IconName;
  url?: string;
}

export let getFooterLinks = (): FooterLink[] => {
  return [];
};

export function getVersion(): string {
  if (config.buildInfo) {
    return config.buildInfo.version || '';
  } else {
    return '';
  }
}

export interface Props {
  /** Link overrides to show specific links in the UI */
  customLinks?: FooterLink[] | null;
}

export const Footer = React.memo(() => {
  const getStyles = (theme: GrafanaTheme2) => {
    return {
      footer: css`
        width: 100%;
        background: ${theme.colors.background.secondary};
      `,
      footerContent: css`
        display: flex;
        margin-left: 10%;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 2em;
        gap: 4%;
        font-size: 12px;
        & ul {
          list-style: none;
          & li a:hover {
            text-decoration: underline;
          }
        }
      `,
      logo: css`
        width: 121px;
        margin-top: 1em;
      `,
      version: css`
        color: gray;
      `,
    };
  };

  const styles = useStyles2(getStyles);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <img className={styles.logo} src="public/img/Choppycast_logo_svart.svg" alt="Grafana" />
        <ul>
          <li>Årøyvegen 7</li>
          <li>6856 Sogndal</li>
          <li>Norge</li>
        </ul>
        <ul>
          {/* Mailto link */}
          <li>
            <a href="mailto:post@frivind.no">post@frivind.no</a>
          </li>
          {/* Phone number */}
          <li>
            <a href="tel:+4791755306">+47 917 55 306</a>
          </li>
        </ul>
        <ul>
          {/* Website */}
          <li>
            <a href="https://www.choppycast.com" target="_blank" rel="noreferrer">
              choppycast.com
            </a>
          </li>
          {/* Privacy Policy */}
          <li>
            <a href="https://www.choppycast.com/privacy-policy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </li>
          <li>
            <span className={styles.version}>{getVersion()}</span>
          </li>
        </ul>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';

import { createTheme, GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime/src/services/backendSrv';

import { CustomTheme } from './custom_theme';

export class FrivindCustomTheme {
  constructor() {}

  // Async get theme from /api/orgs/:orgId/theme
  private async getCustomTheme(): Promise<GrafanaTheme2[]> {
    const themes: CustomTheme[] = await getBackendSrv().get(`/api/org/theme`);

    // Throw error if no theme is found
    if (!themes) {
      throw new Error('No theme found');
    }

    return themes.map((theme) => {
      const themeJson = JSON.parse(theme.Json);
      return createTheme(themeJson);
    });
  }

  async loadCustomThemes(): Promise<GrafanaTheme2[]> {
    try {
      const customThemes = await this.getCustomTheme();
      return customThemes;
    } catch (err) {
      throw err;
    }
  }
}
